import { async } from "@firebase/util";
import { active } from "d3-transition";

//import { withRouter } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword, signInWithRedirect, signInWithPopup, signInWithEmailAndPassword,
         GoogleAuthProvider, getRedirectResult, signOut, onAuthStateChanged, updateProfile } from "firebase/auth";

const auth = (typeof window !== 'undefined') ? getAuth() : null;
var newName = "";
//Auth User Functions
export function getUser(){
    return auth.currentUser;
}

//Sign In Functions
export async function googleSignIn()
{
    var provider = new GoogleAuthProvider();
    await signInWithRedirect(auth,provider);
    var result = await getRedirectResult()
    if (result.credential) {
      var credential = result.credential;
      //Google Access Token.
      var token = credential.accessToken;
    }
    // The signed-in user info.
    
    var user = result.user;   
}

export async function registerUser(email, password, firstName, lastName)
{
    //console.log(email, password);
    try {
        var credential = await createUserWithEmailAndPassword(auth, email, password);
        newName = firstName + " " + lastName;
    } catch (error) {
        throw new Error(`Something went wrong while registering: [${error.code}] - ${error.message})`);
    }
}
export async function registerWithGoogle()
{
    var provider = new GoogleAuthProvider();
    
    try {
        const result = await signInWithPopup(auth, provider);
        var credential = GoogleAuthProvider.credentialFromResult(result);
        //Google Access Token.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
    } catch (error) {
        // The email of the user's account used.
        const email = error.email;
        throw new Error(`Something went wrong while signing in with Google: [${error.code}] - ${error.message})`);
    }
}

export async function emailSignIn(email, password)
{
    let userCredential = await signInWithEmailAndPassword(auth ,email, password);
    //Return to the home page
}
/** 
onAuthStateChanged(auth, (user) => {
  if (user) 
  {
    updateProfile(user,
        {
            "displayName": user.displayName??newName,
            "photoURL": user.photoURL??"" 
        }
        );
  } else {
  }
});
**/
export async function firebaseSignOut()
{
    await signOut(auth);
}